import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SkillsComponent } from './skills/skills.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AppRouteModule } from './app-route-module';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsComponent } from './projects/projects.component';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { ModalService } from './modal.service';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    AppComponent,
    SkillsComponent,
    FooterComponent,
    HeaderComponent,
    ErrorPageComponent,
    HomeComponent,
    ProjectsComponent,
    ModalContentComponent
  ],
  imports: [
    BrowserModule,
    AppRouteModule,
    ModalModule.forRoot(),
    NgbModule.forRoot()
  ],
  providers: [ModalService, BsModalService],
  bootstrap: [AppComponent],
  entryComponents: [ModalContentComponent]
})
export class AppModule { }
