import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkillsComponent } from './skills/skills.component';
import { HomeComponent } from './home/home.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ProjectsComponent } from './projects/projects.component';

const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'skills', component: SkillsComponent},
  {path: 'projects', component: ProjectsComponent},
  {path: '**', component: ErrorPageComponent, data: {message: 'Page not found!'}}, // This is the error handler
  // {path: '**', redirectTo: '/not-found'} // '**' in the path string is a catchall when no other paths match.
];

@NgModule({
  imports: [
    // this can be used if the web server can not 404 every page request to index.html
    // RouterModule.forRoot(appRoutes, {useHash: true})
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})

export class AppRouteModule {

}
