import { Injectable } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalContentComponent } from './modal-content/modal-content.component';

@Injectable()

export class ModalService {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  openModalFromComponent(title: string) {
    const initialState = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: title
    };

    this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

}
